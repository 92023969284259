import { AnimalSvgs } from "../components/Visualisers/Animals/AnimalsSvgs";
import { ShapeSvgs } from "../components/Visualisers/Shapes/ShapesSVG";

export const alphabetData =
  "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z".split(",");

export const colourData = [
  { hex: "#FF0000", name: "Red" },
  { hex: "#0000FF", name: "Blue" },
  { hex: "#FFFF00", name: "Yellow" },
  { hex: "#FFA500", name: "Orange" },
  { hex: "#008000", name: "Green" },
  { hex: "#800080", name: "Purple" },
  { hex: "#FFC0CB", name: "Pink" },
  { hex: "#000000", name: "Black" },
  { hex: "#800000", name: "Brown" },
  { hex: "#FFFFFF", name: "White" },
];

export const numericData =
  "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20".split(",");

export const shapeData = Object.entries(ShapeSvgs);
export const animalData = Object.entries(AnimalSvgs);
