import { useRef, useState } from "react";

export const useAudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob>();
  const mediaRecorderRef = useRef<MediaRecorder>();
  const chunksRef = useRef<Blob[]>([]);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        chunksRef.current = [];

        mediaRecorderRef.current.start();

        mediaRecorderRef.current.ondataavailable = (e) => {
          chunksRef.current.push(e.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const blob = new Blob(chunksRef.current, { type: "audio/mp3" });
          setAudioBlob(blob);
          setIsRecording(false);
        };
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });

    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
    }
  };

  return { isRecording, audioBlob, startRecording, stopRecording };
};

export const downloadAudioBlob = (audioBlob: Blob, filename: string) => {
  const audioUrl = URL.createObjectURL(audioBlob);
  const downloadLink = document.createElement("a");
  downloadLink.href = audioUrl;
  downloadLink.download = `${filename}.mp3`;
  downloadLink.click();
  URL.revokeObjectURL(audioUrl);
};
