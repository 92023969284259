export const getRandomBrightColor = (minBrightness: number = 1) => {
  // const color = `rgb(${
  //   minBrightness + Math.floor(Math.random() * (255 - minBrightness))
  // }, ${minBrightness + Math.floor(Math.random() * (255 - minBrightness))}, ${
  //   minBrightness + Math.floor(Math.random() * (255 - minBrightness))
  // })`;

  // // Convert to hex
  // const hexColor = color
  //   .match(/\d+/g)
  //   ?.reduce(
  //     (hex, x) =>
  //       (hex += (parseInt(x) < 16 ? "0" : "") + parseInt(x).toString(16)),
  //     "#"
  //   );

  return getFunColorForToddlers(); //hexColor ?? "#FF0000";
};

export const hexToPastel = (hex: string, bright?: boolean) => {
  let [h, s, l] = hexToHsl(hex);

  if (bright) {
    // Subtle increase in lightness and saturation for a slightly brighter pastel effect
    l = Math.min(110, l + 10); // Increase lightness a little
    s = Math.min(60, s + 10); // Slightly increase saturation
  } else {
    // Subtle increase in lightness and saturation for a slightly brighter pastel effect
    l = Math.min(40, l + 30); // Increase lightness a little
    s = Math.min(30, s - 30); // Slightly increase saturation
  }

  const rgb = hslToRgb(h, s, l);
  return rgbToHex(rgb[0], rgb[1], rgb[2]);
};

export const getComplementaryColor = (hexColor: string): string => {
  // Remove the hash from the color if present
  hexColor = hexColor.replace("#", "");

  // Convert two character hex values to decimal
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Get the complementary color by subtracting from 255
  const compR = (255 - r).toString(16).padStart(2, "0");
  const compG = (255 - g).toString(16).padStart(2, "0");
  const compB = (255 - b).toString(16).padStart(2, "0");

  // Concatenate the hex values to get the complementary color
  return `#${compR}${compG}${compB}`;
};

export const getRainbowColor = (index: number, length: number) => {
  const hue = (index / length) * 360;
  const saturation = 100;
  const lightness = 50;

  const rgb = hslToRgb(hue, saturation, lightness);
  return rgbToHex(rgb[0], rgb[1], rgb[2]);
};

export const getReverseRainbowColor = (index: number, length: number) => {
  const hue = 360 - (index / length) * 360;
  const saturation = 100;
  const lightness = 50;

  const rgb = hslToRgb(hue, saturation, lightness);
  return rgbToHex(rgb[2], rgb[1], rgb[0]);
};

export const getFunColorForToddlers = () => {
  const hue = Math.random() * 360; // Random hue between 0 and 360
  const saturation = 80 + Math.random() * 20; // Random saturation between 80% and 100%
  const lightness = 40 + Math.random() * 20; // Random lightness between 40% and 60%
  const rgb = hslToRgb(hue, saturation, lightness);
  return rgbToHex(rgb[0], rgb[1], rgb[2]);
};

const hslToRgb = (h: number, s: number, l: number) => {
  h /= 360;
  s /= 100;
  l /= 100;

  // const r = l - l * s;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) => {
    const k = (n + h * 12) % 12;
    return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  };

  return [f(0), f(8), f(4)];
};

const hexToHsl = (hex: string) => {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h: number = 0;
  let s: number = 0;
  let l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h * 360, s * 100, l * 100];
};

const rgbToHex = (r: number, g: number, b: number) => {
  return (
    "#" +
    [r, g, b]
      .map((x: number) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

export const getContrastingColor = (hexColor: string) => {
  const r = parseInt(hexColor.slice(1, 3), 16) / 255;
  const g = parseInt(hexColor.slice(3, 5), 16) / 255;
  const b = parseInt(hexColor.slice(5, 7), 16) / 255;

  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/Android/i.test(ua)) return "Android";
  else if (/iPhone|iPad|iPod/i.test(ua)) return "iOS";
  else return "other";
};
