export const AlphabeticalSounds: { [key: string]: string } = {
  A: "Aah",
  B: "Bahh",
  C: "Kerr",
  D: "Deh",
  E: "Eghr",
  F: "Faah",
  G: "Gugh",
  H: "Heghr",
  I: "Eah",
  J: "Jegh",
  K: "Kegh",
  L: "Legh",
  M: "Megh",
  N: "Negh",
  O: "Ogh",
  P: "Pegh",
  Q: "Kegh",
  R: "Regh",
  S: "Sssegh",
  T: "Tegh",
  U: "Aaugh",
  V: "Vegh",
  W: "Wegh",
  X: "Zegh",
  Y: "Yegh",
  Z: "Zerr",
};
