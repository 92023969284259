import { Button } from "@mui/material";
import { useSpeechRecognition } from "../../Audibilisers/SpeechRecognition/SpeechRecognition";
import { Mic, MicOff } from "@mui/icons-material";

type Props = {
  callback: (result: string) => void;
};
export const SpeechRecognitionButton = ({ callback }: Props) => {
  const { start, abort, listening } = useSpeechRecognition(callback);

  return (
    <Button onClick={() => (listening ? abort() : start())}>
      {listening ? <Mic /> : <MicOff />}
    </Button>
  );
};
