import { useState } from "react";
import { Minimiser } from "../Minimiser/Minimiser";
import { SelectionBox } from "./SelectionBox/SelectionBox";
import { useAlphabetMinimiser } from "./data";
import { alphabetData } from "../../../store/data";

type Props = {
  callback: (shape: string, colour: string) => void;
};

export const AlphabetPicker = ({ callback }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const alphabetMinimiser = useAlphabetMinimiser();
  return (
    <>
      <Minimiser
        contents={alphabetMinimiser}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen &&
        alphabetData.map((char, i) => (
          <SelectionBox
            key={char}
            index={i}
            count={alphabetData.length}
            id={char}
            colour={"black"}
            background={"random"}
            callback={callback}
            brightness={70}
          >
            {char}
          </SelectionBox>
        ))}
    </>
  );
};
