import { Canvas } from "@react-three/fiber";
import { ReactNode, useEffect, useRef } from "react";
import { DimensionalCamera } from "../components/Tools/DimensionalCamera/DimensionalCamera";
import { OrbitControls } from "@react-three/drei";
import { Mesh, MeshPhongMaterial, PlaneGeometry } from "three";
import classes from "./GameCanvas.module.scss";

type Props = {
  children: ReactNode;
  orbitEnabled?: boolean;
};
export const GameCanvas = ({ children, orbitEnabled }: Props) => {
  return (
    <>
      <Canvas className={`${classes.editorCanvas} ${!orbitEnabled ? classes.disabled : ""}`}>
        {children}
        <DimensionalCamera />
        {/* <Environment preset="city" background blur={1} /> */}
        {/* <ContactShadows
          resolution={512}
          position={[0, -0.8, 0]}
          opacity={1}
          scale={10}
          blur={2}
          far={0.8}
        /> */}
        {/* <Ground /> */}
        <ambientLight intensity={0.1} />
        <directionalLight color="white" position={[0, 0, 5]} />
        {orbitEnabled && <OrbitControls zoomSpeed={1} />}
      </Canvas>
    </>
  );
};

const Ground = () => {
  const meshRef = useRef<Mesh>(null!);

  useEffect(() => {
    meshRef.current.material = new MeshPhongMaterial({
      color: 0xcbcbcb,
      depthWrite: false
    });

    meshRef.current.rotation.x = -Math.PI / 2;
    meshRef.current.geometry = new PlaneGeometry(500, 500);
  });

  return (
    <>
      <mesh ref={meshRef} />
      <gridHelper args={[200, 30, 0x000000, 0x000000]} />
    </>
  );
};
