// Our pronunciation dictionary
export const PronunciationDict: { [id: string]: string } = {
  see: "C",
  sea: "C",
  bee: "B",
  tea: "T",
  eye: "I",
  why: "Y",
  jay: "J",
  kay: "K",
  ell: "L",
  em: "M",
  en: "N",
  oh: "O",
  pee: "P",
  queue: "Q",
  are: "R",
  ess: "S",
  you: "U",
  vee: "V",
  double: "W",
  ex: "X",
  zed: "Z",
  zedd: "Z",
  zee: "Z",
  aitch: "H",
  haitch: "H",
  gee: "G",
  dee: "D",
  eff: "F",
  won: "1",
  too: "2",
  to: "2",
  two: "2",
  for: "4",
  fore: "4",
  ate: "8",
  ten: "10",
  cee: "C",
  ef: "F",
  el: "L",
  cue: "Q",
  tee: "T",
  "double-you": "W",
  "double you": "W",
  wye: "Y",
  axe: "X",
};
