// TextInputButton.tsx
import React, { useRef, useEffect, useState } from "react";
import classes from "./KeyboardButton.module.scss";
import { upperCase } from "lodash";
import { useKeyboardState } from "../Grid/Grid";

interface TextInputButtonProps {
  onChange: (value: string) => void;
}

export const TextInputButton: React.FC<TextInputButtonProps> = ({
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    const lastChar = upperCase(val.charAt(val.length - 1));
    setValue(lastChar);
    onChange(lastChar);
  };

  return (
    <input
      type="text"
      className={classes.inputField}
      onChange={handleChange}
      ref={inputRef}
      onFocus={() => useKeyboardState.setState({ isKeyboardOpen: true })}
      onBlur={() => useKeyboardState.setState({ isKeyboardOpen: false })}
      value={value}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
    />
  );
};
