import { useRef, useEffect, PropsWithChildren } from "react";
import Hammer from "hammerjs";

type Props = {};
export const PreventSwipeComponent = ({
  children,
}: PropsWithChildren<Props>) => {
  const elementRef = useRef(null!);

  useEffect(() => {
    const hammer = new Hammer(elementRef.current);

    hammer.get("swipe").set({ direction: Hammer.DIRECTION_ALL });
    hammer.on("swipe", (e) => {
      e.preventDefault();
    });

    return () => {
      hammer.off("swipe");
    };
  }, []);

  useEffect(() => {
    const preventDefaultBehavior = (e: TouchEvent) => e.preventDefault();
    document.addEventListener("touchmove", preventDefaultBehavior, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchmove", preventDefaultBehavior);
    };
  }, []);

  return (
    <div ref={elementRef} style={{ width: "inherit", height: "inherit" }}>
      {children}
    </div>
  );
};
