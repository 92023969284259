import { SpeakArgs } from "../../Audibilisers/SpeechSynthesis/SpeechSynthesis";
import { AnimalSvgs } from "../Animals/AnimalsSvgs";
import { LetterAnimation } from "../Animation/LetterAnimation";
import { SVGMesh } from "../Shapes/Shapes3D";
import { ShapeSvgs } from "../Shapes/ShapesSVG";
import { Text } from "../Text/Text";

export type ColourSelectionItems = {
  title: string;
  colour: string;
};

type Props = {
  selectionItems: ColourSelectionItems[];
  speak: (args?: SpeakArgs) => void;
  voices: SpeechSynthesisVoice[];
  selectedColour: string;
};
export const Renderer = ({
  selectionItems,
  voices,
  selectedColour,
  speak,
}: Props) => {
  return (
    <>
      {[...selectionItems].reverse().map(
        (character, index) =>
          index === 0 && (
            <group
              key={index}
              onPointerDown={() =>
                speak({
                  text: character.title,
                  voice: voices.filter((voice) => voice.lang === "en-AU")[0],
                })
              }
            >
              <LetterAnimation key={index} disableRotation>
                {ShapeSvgs[character.title] || AnimalSvgs[character.title] ? (
                  <SVGMesh colour={character.colour} shape={character.title} />
                ) : (
                  <Text text={character.title} colour={selectedColour} />
                )}
              </LetterAnimation>
            </group>
          )
      )}
    </>
  );
};
