import { useState } from "react";
import { Minimiser } from "../Minimiser/Minimiser";
import { ShapeSvgs } from "../Shapes/ShapesSVG";
import { SelectionBox } from "./SelectionBox/SelectionBox";
import { useShapeMinimiser } from "./data";

type Props = {
  callback: (shape: string, colour: string) => void;
};

export const ShapePicker = ({ callback }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const shapeMinimiser = useShapeMinimiser();
  return (
    <>
      <Minimiser contents={shapeMinimiser} onClick={() => setIsOpen(!isOpen)} />

      {isOpen &&
        Object.entries(ShapeSvgs).map((shape, i) => (
          <SelectionBox
            key={shape[0]}
            id={shape[0]}
            index={i}
            count={Object.entries(ShapeSvgs).length}
            colour={"random"}
            brightness={10}
            callback={callback}
          >
            {shape[1]}
          </SelectionBox>
        ))}
    </>
  );
};
