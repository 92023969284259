import { useThree } from "@react-three/fiber";
import { useRef, useEffect, useMemo } from "react";
import { DoubleSide, Euler, Group, MeshStandardMaterial, Vector3 } from "three";
import { ExtrudeGeometry, Mesh } from "three";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import { shapeMappings } from "./ShapesSVG";
import { animalMappings } from "../Animals/AnimalsSvgs";
import { Svg } from "@react-three/drei";

type Props = {
  shape: string;
  colour: string;
};

const getPathData = (shape: string) => {
  const pathData = shapeMappings[shape] || animalMappings[shape];
  const svgMarkup = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path d="${pathData}" /></svg>`;
  return svgMarkup;
};

export const SVGMesh = ({ shape, colour }: Props) => {
  const ref = useRef<Group>(null!);
  const { scene } = useThree();

  const material = useMemo(
    () =>
      new MeshStandardMaterial({
        color: colour,
        side: DoubleSide,
      }),
    [colour]
  );

  const transparent = useMemo(
    () =>
      new MeshStandardMaterial({
        color: "white",
        side: DoubleSide,
      }),
    []
  );

  useEffect(() => {
    const pathData = getPathData(shape);

    const depth = 0.1;
    ref.current.clear();
    // Load SVG file
    new SVGLoader().load(
      `data:image/svg+xml,${encodeURIComponent(pathData)}`,
      (data) => {
        const paths = data.paths;
        const group = ref.current;

        // Process each path
        for (let i = 0; i < paths.length; i++) {
          const path = paths[i];
          const shapes = path.toShapes(true);

          // Extrude each shape
          for (let j = 0; j < shapes.length; j++) {
            const shape = shapes[j];
            const geometry = new ExtrudeGeometry(shape, {
              depth: depth * 10,
              bevelEnabled: false,
            });
            const mesh = new Mesh(geometry, transparent);

            group.add(mesh);
          }
        }

        group.scale.set(0.1, 0.1, 0.1);
        group.position.set(-1, 1, 0);
      }
    );
  }, [shape, material, transparent, scene]);

  return (
    <>
      <Svg
        src={getPathData(shape)}
        scale={new Vector3(0.1, 0.1, 0.1)}
        position={new Vector3(-1, 1, 0.01)}
        fillMaterial={material as any}
        strokeMaterial={material as any}
      />

      <group ref={ref} rotation={new Euler(0, Math.PI, Math.PI)} />
    </>
  );
};
