import React, { useState } from "react";
import classes from "./PersonalisedPhonetics.module.scss";
import { AudioRecorder } from "../AudioRecorder";
import { downloadAudioBlob, useAudioRecorder } from "../useAudioRecorder";
import { AudioFile, Download, Downloading, Save } from "@mui/icons-material";

const characters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const audioUrl = "https://storage.cloud.google.com/audiostorage-sebsgame/A.mp3";

export const PersonalisedPhonetics = () => {
  const [character, setCharacter] = useState<string>();
  const { audioBlob, isRecording, startRecording, stopRecording } =
    useAudioRecorder();

  const handleKeyDown = (char: string) => {
    startRecording();
    setCharacter(char);
  };

  const handleKeyUp = (char: string) => {
    stopRecording();
  };

  const handlePlay = (char: string) => {
    // if (audioBlob) {
    const audio = new Audio(audioUrl);
    audio.play();
    // }
  };

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.header}>
            <th>Character</th>
            <th>Recorder</th>
            <th>Play</th>
          </tr>
        </thead>
        <tbody>
          {characters.map((char) => (
            <tr key={char}>
              <td>{char}</td>
              <td>
                <div
                  onMouseDown={() => handleKeyDown(char)}
                  onPointerDown={() => handleKeyDown(char)}
                  onMouseUp={() => handleKeyUp(char)}
                  onPointerUp={() => handleKeyUp(char)}
                >
                  <AudioFile />
                </div>

                {character === char && (
                  <>
                    {isRecording && <Downloading />}

                    {audioBlob && (
                      <Save
                        onClick={() => downloadAudioBlob(audioBlob, char)}
                      />
                    )}
                  </>
                )}
              </td>
              <td>
                {/* {audioBlob && char === character ? ( */}
                <button
                  className={classes.playButton}
                  onClick={() => handlePlay(char)}
                >
                  Play
                </button>
                {/* ) : (
                  "Please Record First"
                )} */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
