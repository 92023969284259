import { useEffect, useMemo, useRef } from "react";
import classes from "./Grid.module.scss";
import { PreventSwipeComponent } from "../../../../../utils/PreventMobileSwipe";
import { create } from "zustand";
import { getDeviceType } from "../../../utils";

type State = { isKeyboardOpen: boolean };
export const useKeyboardState = create(
  (): State => ({ isKeyboardOpen: false })
);

type Props = {
  topbar: JSX.Element | null;
  leftSidebar?: JSX.Element | null;
  mainContent: JSX.Element | null;
  rightSidebar?: JSX.Element | null;
  bottombar: JSX.Element | null;
};
export const Grid = ({
  topbar,
  leftSidebar,
  mainContent,
  rightSidebar,
  bottombar: bottomBar,
}: Props) => {
  const topbarRef = useRef<HTMLDivElement>(null!);
  const bottombarRef = useRef<HTMLDivElement>(null!);
  const leftSideRef = useRef<HTMLDivElement>(null!);
  const rightSidebarRef = useRef<HTMLDivElement>(null!);
  const middleRef = useRef<HTMLDivElement>(null!);
  const isKeyboardOpen = useKeyboardState((state) => state.isKeyboardOpen);

  useEffect(() => {
    // Define a function to update the height
    const updateHeight = () => {
      middleRef.current.style.height =
        window.innerHeight -
        topbarRef.current.clientHeight -
        bottombarRef.current.clientHeight +
        "px";
    };

    // Run the function initially
    updateHeight();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const deviceType = useMemo(() => getDeviceType(), []);

  return (
    <PreventSwipeComponent>
      <div
        className={`${classes.container} ${
          deviceType === "other" ? classes.nonMobile : ""
        }`}
      >
        <div ref={topbarRef} className={classes.topbar}>
          {topbar}
        </div>
        <div ref={middleRef} className={classes.middle}>
          <div ref={leftSideRef} className={classes.leftSidebar}>
            {leftSidebar}
          </div>
          <div
            className={classes.mainContent}
            style={{ height: isKeyboardOpen ? "40svh" : undefined }}
          >
            {mainContent}
          </div>
          <div ref={rightSidebarRef} className={classes.rightSidebar}>
            {rightSidebar}
          </div>
        </div>
        <div ref={bottombarRef} className={classes.bottombar}>
          {bottomBar}
        </div>
      </div>
    </PreventSwipeComponent>
  );
};
