import { AnimalSvgs } from "../components/Visualisers/Animals/AnimalsSvgs";
import { ShapeSvgs } from "../components/Visualisers/Shapes/ShapesSVG";
import { alphabetData, colourData, numericData } from "./data";

export type CategoryType = {
  id: string;
  title?: string;
  background?: string | "random";
  colour?: string | "random";
  border?: string;
  brightness?: number;
  content: string | React.ReactElement;
  callbackParams: { name: string; colour?: string; updateColour?: boolean };
};

export type StateType = {
  [category: string]: CategoryType[];
};

export const initialGameState: StateType = {
  alphabet: alphabetData.map((char) => ({
    id: char,
    colour: "contrasting",
    background: "rainbow",
    brightness: 200,
    content: char,
    callbackParams: { name: char },
  })),
  colours: colourData.map((colour) => ({
    id: colour.hex,
    title: colour.name,
    background: colour.hex,
    border: "silver",
    content: "",
    callbackParams: {
      name: colour.name,
      colour: colour.hex,
      updateColour: true,
    },
  })),
  numeric: numericData.map((char) => ({
    id: char,
    colour: "contrasting",
    background: "rainbow-reverse",
    brightness: 220,
    content: char,
    callbackParams: { name: char },
  })),
  shapes: Object.entries(ShapeSvgs).map((shape) => ({
    id: shape[0],
    colour: "random",
    brightness: 10,
    content: shape[1],
    callbackParams: { name: shape[0] },
  })),
  animals: Object.entries(AnimalSvgs).map((animal) => ({
    id: animal[0],
    colour: "random",
    brightness: 10,
    content: animal[1],
    callbackParams: { name: animal[0] },
  })),
};
