import { Center, Text3D } from "@react-three/drei";
import { Suspense } from "react";

type Props = {
  text: string;
  colour: string;
};
export const Text = ({ text, colour }: Props) => {
  return (
    <Suspense fallback={null}>
      <Center>
        <Text3D
          font={"/fonts/helvetiker_regular.typeface.json"}
          bevelEnabled
          bevelSize={0.05}
        >
          {text}
          <meshStandardMaterial color={colour} />
        </Text3D>
      </Center>
    </Suspense>
  );
};
