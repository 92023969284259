import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { EditorElement3D } from "../types";
import { CategoryType, initialGameState } from "./state";

export type GameStoreState = {
  elements: { [id: string]: EditorElement3D<any> };
  selectedCategory: CategoryType[];
  actions: {
    setSelectedCategory: (id: string) => void;

    addElement: (id: string, element: EditorElement3D<any>) => void;
    deleteElement: (id: string) => void;
    updateElement: (id: string, updatedElement: EditorElement3D<any>) => void;
  };
};

type SetState<T extends GameStoreState> = {
  _(
    partial: T | Partial<T> | ((state: T) => T | Partial<T>),
    replace?: boolean | undefined,
    actionName?: string
  ): void;
}["_"];

type GetState<T extends GameStoreState> = {
  _(
    partial: T | Partial<T> | ((state: T) => T | Partial<T>),
    replace?: boolean | undefined,
    actionName?: string
  ): void;
}["_"];

export const useGameStore = create(
  subscribeWithSelector(
    (
      setState: SetState<GameStoreState>,
      getState: GetState<GameStoreState>
    ): GameStoreState => ({
      elements: {},
      selectedCategory: initialGameState["alphabet"],
      actions: {
        setSelectedCategory: (id: string) =>
          setState(() => ({
            selectedCategory: initialGameState[id],
          })),

        addElement: (id: string, element: EditorElement3D<any>) =>
          setState((state) => ({
            elements: {
              ...state.elements,
              [id]: element,
            },
          })),

        deleteElement: (id: string) =>
          setState((state) => {
            const newElements = { ...state.elements };
            delete newElements[id];
            return { elements: newElements };
          }),

        updateElement: (id: string, updatedElement: EditorElement3D<any>) =>
          setState((state) => ({
            elements: {
              ...state.elements,
              [id]: updatedElement,
            },
          })),
      },
    })
  )
);
