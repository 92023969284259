import { PropsWithChildren, useMemo } from "react";
import {
  getContrastingColor,
  getRainbowColor,
  getRandomBrightColor,
  getReverseRainbowColor,
  hexToPastel
} from "../../../../utils";
import classes from "./SelectionBox.module.scss";

type Props = {
  id: string;
  title?: string;
  index: number;
  count: number;
  background?: string | "random";
  colour?: string | "random";
  border?: string;
  disablePastel?: boolean;
  brightness?: number;
  isSelected?: boolean;
  size?: "small" | "medium" | "large";
  callback: (name: string, colour: string) => void;
};
export const SelectionBox = ({
  id,
  title,
  index,
  count,
  background,
  colour,
  border,
  disablePastel,
  brightness = 150,
  isSelected,
  size = "medium",
  callback,
  children
}: PropsWithChildren<Props>) => {
  const backgroundColour = useMemo(() => {
    let backgroundColour = background;
    if (background && background === "random") {
      backgroundColour = getRandomBrightColor(brightness);
    }
    if (background && background === "rainbow") {
      backgroundColour = getRainbowColor(index, count);
    }
    if (background && background === "rainbow-reverse") {
      backgroundColour = getReverseRainbowColor(count - index, count);
    }
    return backgroundColour && !disablePastel ? hexToPastel(backgroundColour) : backgroundColour;
  }, [background, brightness, index, count, disablePastel]);

  const contentColour = useMemo(() => {
    let contentColour = colour;
    if (colour && colour === "random") {
      contentColour = getRandomBrightColor(brightness);
    }
    if (colour && colour === "rainbow") {
      contentColour = getRainbowColor(index, count);
    }
    if (colour && colour === "rainbow-reverse") {
      contentColour = getReverseRainbowColor(count - index, count);
    }
    if (colour && colour === "contrasting" && backgroundColour) {
      contentColour = getContrastingColor(backgroundColour) ?? "black";
    }
    return contentColour;
  }, [colour, brightness, index, count, backgroundColour]);

  return (
    <div
      style={{
        background: backgroundColour,
        color: contentColour,
        fill: contentColour ? hexToPastel(contentColour) : contentColour,
        border: border ? `1px solid ${border}` : undefined
      }}
      className={`${classes.box} ${isSelected ? classes.selected : ""} ${classes[size]}`}
      onPointerDown={() => callback(title || id, backgroundColour ?? contentColour ?? "black")}
    >
      {children}
    </div>
  );
};
