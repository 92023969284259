import React from "react";
import Icon from "@mdi/react";
import * as mdi from "@mdi/js";

export const animalMappings: { [key: string]: string } = {
  bird: mdi.mdiBird,
  butterfly: mdi.mdiButterfly,
  dog: mdi.mdiDogSide,
  dolping: mdi.mdiDolphin,
  duck: mdi.mdiDuck,
  elephant: mdi.mdiElephant,
  fish: mdi.mdiFish,
  horse: mdi.mdiHorse,
  kangaroo: mdi.mdiKangaroo,
  jellyfish: mdi.mdiJellyfish,
  koala: mdi.mdiKoala,
  owl: mdi.mdiOwl,
  panda: mdi.mdiPanda,
  penguin: mdi.mdiPenguin,
  pig: mdi.mdiPigVariant,
  rabbit: mdi.mdiRabbit,
  shark: mdi.mdiShark,
  sheep: mdi.mdiSheep,
  snail: mdi.mdiSnail,
  snake: mdi.mdiSnake,
  spider: mdi.mdiSpiderThread,
  tortoise: mdi.mdiTortoise,
  turkey: mdi.mdiTurkey,
  unicorn: mdi.mdiUnicorn,
  bat: mdi.mdiBat,
  turtle: mdi.mdiTurtle,
  cow: mdi.mdiCow
};
export const AnimalSvgs: { [id: string]: React.ReactElement } = {};
Object.entries(animalMappings).forEach((entry) => {
  AnimalSvgs[entry[0]] = <Icon style={{ width: "inherit", height: "inherit" }} path={entry[1]} color={"inherit"} />;
});
