import { Outlet, createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "../pages/Error";
import { LetterPhoneticsGame } from "../pages/Game/Games/LetterPhoneticsGame";
import { PersonalisedPhonetics } from "../pages/Game/components/Audibilisers/Voices/PersonalisedPhonetics/PersonalisedPhonetics";
import { LetterCaptureGame } from "../pages/Game/Games/LetterCaptureGame";
import { MainGame } from "../pages/Game/Games/MainGame/MainGame";

export type IRoute = {
  title?: string;
  element: JSX.Element;
  errorElement?: JSX.Element;
  path: string;
} & { children?: IRoute[] };
export type IRoutes = IRoute[];

export const routes: IRoutes = [
  {
    element: (
      <>
        <Outlet />
      </>
    ),
    path: "/",
    errorElement: (
      <>
        <ErrorPage />
      </>
    ),
    children: [
      {
        title: "Home",
        element: <MainGame />,
        path: "/"
      },
      {
        title: "Home",
        element: <MainGame />,
        path: "/game"
      },
      {
        title: "Letter Capture",
        element: <LetterCaptureGame />,
        path: "/game/capture"
      },
      {
        title: "Letter Sound",
        element: <LetterPhoneticsGame />,
        path: "/game/sound"
      },
      {
        title: "Recordings",
        element: <PersonalisedPhonetics />,
        path: "/recordings"
      }
    ]
  }
];

export const browserRouter = createBrowserRouter(routes);
