import { OrthographicCamera, PerspectiveCamera } from "@react-three/drei";
import { useState } from "react";
import { Vector3 } from "three";

const defaultCamera = {
  makeDefault: true,
  position: new Vector3(0, 0, 20),
  near: 0.1,
  far: 1000,
  fov: 30,
};

export const DimensionalCamera = () => {
  const [cameraType] = useState<"orthographic" | "perspective">("perspective");

  if (cameraType === "orthographic") {
    return <OrthographicCamera {...defaultCamera} />;
  }
  return <PerspectiveCamera {...defaultCamera} />;
};
