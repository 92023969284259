import { capitalize } from "lodash";
import { useState } from "react";
import { GameCanvas } from "../GameCanvas/GameCanvas";
import { LeftSidebar } from "../LeftSidebar/LeftSidebar";
import { NumberSounds } from "../components/Audibilisers/Numbers/numbers";
import { AlphabeticalSounds } from "../components/Audibilisers/Sounds/sounds";
import { useSpeechSynthesis } from "../components/Audibilisers/SpeechSynthesis/SpeechSynthesis";
import { Portal, PortalDiv } from "../components/Tools/Portal/Portal";
import { WiggleModal } from "../components/Visualisers/Modal/WiggleModal";
import { ColourPicker } from "../components/Visualisers/Selections/ColourPicker";
import { LetterCaptureAnimation } from "../components/Visualisers/Animation/LetterCaptureAnimation";

export const LetterCaptureGame = () => {
  const { speak, voices } = useSpeechSynthesis();

  const [characters, setCharacters] = useState<
    { character: string; colour: string }[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [colour, setColour] = useState("#FF0000");

  const handleKeyDown = (key: string, isMatching: boolean) => {
    const letter = capitalize(key);
    setIsModalOpen(false);

    const chars = [...characters, { character: letter, colour: colour }];
    setCharacters(chars);

    if ((!AlphabeticalSounds[letter] && !NumberSounds[letter]) || !isMatching)
      return;

    speak({
      text: letter,
      voice: voices.filter((voice) => voice.lang === "en-AU")[0],
    });

    if (!AlphabeticalSounds[letter]) return;
    setTimeout(() => {
      speak({
        text: AlphabeticalSounds[letter],
        rate: 2,
        voice: voices.filter((voice) => voice.lang === "en-AU")[0],
      });
    }, 1200);
  };

  const handleColorChange = (c: { hex: string; name: string }) => {
    setColour(c.hex);
    speak({
      text: c.name,
      voice: voices.filter((voice) => voice.lang === "en-AU")[0],
    });
  };

  return (
    <>
      {isModalOpen && (
        <WiggleModal
          title={"LETTER CAPTURE!!"}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <Portal portalId="colour-picker">
        <ColourPicker selectedColour={colour} callback={handleColorChange} />
      </Portal>

      <Portal portalId="characters">
        <LeftSidebar>
          <ol className="absolute relative border-l-2 border-pink-200 dark:border-red-700 ml-2">
            {[...characters].reverse().map((character, index) => (
              <li key={index} className="mb-10 ml-6 text-xl">
                <span
                  style={{
                    marginLeft: "-46px",
                    color: character.colour,
                    fontFamily: "Comic Sans MS, cursive, sans-serif",
                    fontWeight: "700",
                  }}
                  className="flex items-center justify-center w-10 h-10 bg-white rounded-full -left-3 ring-2 ring-white dark:ring-gray-900 dark:bg-blue-900"
                >
                  {character.character}
                </span>
              </li>
            ))}
          </ol>
        </LeftSidebar>
      </Portal>
      <GameCanvas>
        {!isModalOpen && (
          <LetterCaptureAnimation callback={handleKeyDown} colour={colour} />
        )}
      </GameCanvas>

      <PortalDiv portalId="colour-picker" />
      <PortalDiv portalId="characters" />
    </>
  );
};
