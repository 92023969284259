import { useState } from "react";
import { Minimiser } from "../Minimiser/Minimiser";
import { SelectionBox } from "./SelectionBox/SelectionBox";
import { colourData } from "../../../store/data";

type Props = {
  selectedColour: string;
  callback: (colour: { hex: string; name: string }) => void;
};
export const ColourPicker = ({ selectedColour, callback }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <Minimiser onClick={() => setIsOpen(!isOpen)} />

      {isOpen &&
        colourData.map((colour, i) => (
          <SelectionBox
            key={colour.hex}
            id={colour.hex}
            index={i}
            count={colourData.length}
            callback={() => callback({ hex: colour.hex, name: colour.name })}
            background={colour.hex}
            isSelected={selectedColour === colour.hex}
            border={"silver"}
            disablePastel
          />
        ))}
    </>
  );
};
