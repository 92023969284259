import { useEffect, useRef, useState } from "react";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

export const useSpeechRecognition = (onResult?: (result: string) => void) => {
  const [listening, setListening] = useState<boolean>(false);
  const [supported, setSupported] = useState<boolean>(false);

  const recognitionRef = useRef<SpeechRecognition | null>(null);

  useEffect(() => {
    if (!SpeechRecognition) {
      console.error("Speech Recognition is not supported in your browser");
      return;
    }

    setSupported(true);

    const recognition = new SpeechRecognition();
    recognition.continuous = true; // Enable continuous recognition
    recognition.interimResults = true; // Get interim results

    const onStart = () => {
      setListening(true);
    };
    const onEnd = () => {
      setListening(false);
      if (recognitionRef.current) {
        // recognitionRef.current.start(); // Restart recognition when it ends
      }
    };

    recognition.onstart = () => onStart();
    recognition.onend = () => onEnd();
    recognition.onerror = (event: any) => console.error(event.error);

    recognition.onresult = (event: any) => {
      const current = event.resultIndex;
      const transcript = event.results[current][0].transcript;

      onResult?.(transcript);
    };

    recognitionRef.current = recognition;

    return () => {
      recognition.stop();
    };
  }, [onResult]); // Empty dependency array means this effect runs once when the component mounts

  // Function for starting speech recognition
  const start = () => {
    if (!recognitionRef.current) {
      return;
    }
    recognitionRef.current.start();
    setListening(true);
  };

  // Function for stopping speech recognition
  const stop = () => {
    if (!recognitionRef.current) {
      return;
    }
    recognitionRef.current.stop();
    setListening(false);
  };

  // Function for canceling speech recognition
  const abort = () => {
    if (!recognitionRef.current) {
      return;
    }
    recognitionRef.current.abort();
    setListening(false);
  };

  return {
    supported,
    listening,
    start,
    stop,
    abort,
  };
};
