import { capitalize, lowerCase } from "lodash";
import { useState } from "react";
import { GameCanvas } from "../../GameCanvas/GameCanvas";
import { NumberSounds } from "../../components/Audibilisers/Numbers/numbers";
import { AlphabeticalSounds } from "../../components/Audibilisers/Sounds/sounds";
import { useSpeechSynthesis } from "../../components/Audibilisers/SpeechSynthesis/SpeechSynthesis";
import { KeyboardInteraction } from "../../components/Tools/KeyboardInteraction/KeyboardInteraction";
import { Grid } from "../../components/Visualisers/Grid/Grid";
import { useGameStore } from "../../store/store";
import { SelectionBox } from "../../components/Visualisers/Selections/SelectionBox/SelectionBox";
import { Minimiser } from "../../components/Visualisers/Minimiser/Minimiser";
import { ColourSelectionItems, Renderer } from "../../components/Visualisers/Renderer/Renderer";
import { useMinimisers } from "../../components/Visualisers/Selections/data";
import { ColourPicker } from "../../components/Visualisers/Selections/ColourPicker";
import { TextInputButton } from "../../components/Visualisers/KeyboardButton/KeyboardButton";
import { PronunciationDict } from "../../components/Audibilisers/SpeechRecognition/pronunciationDict";
import { SpeechRecognitionButton } from "../../components/Visualisers/SpeechRecognitionButton/SpeechRecognitionButton";
import { EchoComponent } from "../../components/Audibilisers/Echo/Echo";

export const MainGame = () => {
  const selectedCategory = useGameStore((state) => state.selectedCategory);
  const setSelectedCategory = useGameStore((state) => state.actions.setSelectedCategory);
  const minimisers = useMinimisers();
  const { speak, voices } = useSpeechSynthesis();

  const [selectionItems, setSelectionItems] = useState<ColourSelectionItems[]>([]);
  const [selectedColour, setSelectedColour] = useState("#FF0000");

  const handleKeyDown = (event: KeyboardEvent) => {
    const letter = capitalize(event.key);
    if (!AlphabeticalSounds[letter] && !NumberSounds[letter]) return;

    setSelectionItems((state) => [...state, { title: letter, colour: selectedColour }]);
    speak({
      text: letter,
      voice: voices.filter((voice) => voice.lang === "en-AU")[0]
    });
  };

  const handleClick = (name: string, col?: string, updateColour?: boolean) => {
    if (col && updateColour) {
      setSelectedColour(col);
      setSelectionItems((state) =>
        state.map((char, i) => (i === state.length - 1 ? { colour: col, title: char.title } : char))
      );

      speak({
        text: `${lowerCase(name)} `,
        voice: voices.filter((voice) => voice.lang === "en-AU")[0]
      });
      speak({
        text: selectionItems.length ? lowerCase(selectionItems[selectionItems.length - 1].title) : "",
        voice: voices.filter((voice) => voice.lang === "en-AU")[0]
      });
    }

    if (!updateColour) {
      setSelectionItems((state) => [...state, { title: name, colour: col ?? selectedColour }]);
      speak({
        text: name,
        voice: voices.filter((voice) => voice.lang === "en-AU")[0]
      });
    }
  };

  const handleSelectMinimiser = (id: string) => {
    setSelectedCategory(id);
  };

  const handleColorChange = (c: { hex: string; name: string }) => {
    setSelectedColour(c.hex);
    setSelectionItems((state) =>
      state.map((char, i) => (i === state.length - 1 ? { colour: c.hex, title: char.title } : char))
    );
    speak({
      text: `${lowerCase(c.name)} `,
      voice: voices.filter((voice) => voice.lang === "en-AU")[0]
    });
    speak({
      text: selectionItems.length ? lowerCase(selectionItems[selectionItems.length - 1].title) : "",
      voice: voices.filter((voice) => voice.lang === "en-AU")[0]
    });
  };

  const handleSpeechRecognitionResult = (string: string) => {
    const value = lowerCase(string);
    handleClick(PronunciationDict[value] ?? value, selectedColour);
  };

  return (
    <Grid
      topbar={
        <>
          {Object.entries(minimisers).map((minimiser) => (
            <Minimiser
              key={minimiser[0]}
              contents={minimiser[1]}
              size={"large"}
              onClick={() => handleSelectMinimiser(minimiser[0])}
            />
          ))}
          <TextInputButton onChange={handleClick} />
          <EchoComponent />
          <SpeechRecognitionButton callback={handleSpeechRecognitionResult} />
        </>
      }
      leftSidebar={<ColourPicker selectedColour={selectedColour} callback={handleColorChange} />}
      mainContent={
        <GameCanvas>
          <KeyboardInteraction onKeyDown={handleKeyDown} />
          <Renderer selectionItems={selectionItems} selectedColour={selectedColour} speak={speak} voices={voices} />
        </GameCanvas>
      }
      bottombar={
        <>
          {selectedCategory.map((item, index) => (
            <SelectionBox
              {...item}
              key={item.id}
              callback={(n, c) =>
                handleClick(
                  item.callbackParams.name,
                  item.callbackParams.updateColour ? item.callbackParams.colour : c,
                  item.callbackParams.updateColour
                )
              }
              index={index}
              count={selectedCategory.length}
              isSelected={selectedColour === item.id}
            >
              {item.content}
            </SelectionBox>
          ))}
        </>
      }
    />
  );
};
