import { useMemo } from "react";
import { alphabetData, numericData } from "../../../store/data";
import { getContrastingColor, getRainbowColor, getRandomBrightColor, getReverseRainbowColor } from "../../../utils";
import { AnimalSvgs } from "../Animals/AnimalsSvgs";
import { ShapeSvgs } from "../Shapes/ShapesSVG";

export const useAlphabetMinimiser = () => {
  const alphabetMinimiser = useMemo(() => {
    return [...alphabetData].slice(0, 9).map((char, index) => {
      const colour = getRainbowColor(index, 9);
      const complimentaryColour = getContrastingColor(colour);

      return (
        <div
          style={{
            background: colour,
            color: complimentaryColour
          }}
        >
          {char}
        </div>
      );
    });
  }, []);

  return alphabetMinimiser;
};

export const useNumericMinimiser = () => {
  const numericMinimiser = useMemo(() => {
    return [...numericData].slice(0, 9).map((char, index) => {
      const colour = getReverseRainbowColor(index, 9);
      const complimentaryColour = getContrastingColor(colour);
      return (
        <div
          style={{
            background: colour,
            color: complimentaryColour
          }}
        >
          {char}
        </div>
      );
    });
  }, []);

  return numericMinimiser;
};

export const useShapeMinimiser = () => {
  return useMemo(() => {
    return Object.entries(ShapeSvgs)
      .slice(0, 9)
      .map((shape) => {
        const colour = getRandomBrightColor();
        return (
          <div
            style={{
              fill: colour
            }}
          >
            {shape[1]}
          </div>
        );
      });
  }, []);
};

export const useAnimalMinimiser = () => {
  return useMemo(() => {
    return Object.entries(AnimalSvgs)
      .slice(0, 9)
      .map((animal) => {
        const colour = getRandomBrightColor();
        return (
          <div
            style={{
              fill: colour
            }}
          >
            {animal[1]}
          </div>
        );
      });
  }, []);
};

export const useMinimisers = () => {
  const alphabetMinimiser = useAlphabetMinimiser();
  const numericMinimiser = useNumericMinimiser();
  const shapeMinimiser = useShapeMinimiser();
  const animalMinimiser = useAnimalMinimiser();

  return {
    alphabet: alphabetMinimiser,
    numeric: numericMinimiser,
    shapes: shapeMinimiser,
    // colours: colourMinimiser,
    animals: animalMinimiser
  };
};
