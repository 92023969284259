import { useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Group } from "three";
import { Text } from "../Text/Text";
import { KeyboardInteraction } from "../../Tools/KeyboardInteraction/KeyboardInteraction";
import { upperCase } from "lodash";

const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");

const getRandomCharacter = () =>
  characters[Math.floor(Math.random() * characters.length)];

const randomDirection = () => (Math.random() > 0.5 ? 1 : -1);

type Props = {
  colour: string;
  callback: (key: string, isMatching: boolean) => void;
};
export const LetterCaptureAnimation = ({ colour, callback }: Props) => {
  const { camera } = useThree();
  const [currentChar, setCurrentChar] = useState(getRandomCharacter);
  const groupRef = useRef<Group>(null!);
  const direction = useRef([randomDirection(), randomDirection(), 0]);
  const velocity = useRef([0.07, 0.07]);

  useFrame(() => {
    if (!groupRef.current) return;
    // Update position
    groupRef.current.position.x += direction.current[0] * velocity.current[0];
    groupRef.current.position.y += direction.current[1] * velocity.current[1];
    // Get the object's position in normalized device coordinates3
    const ndcPosition = groupRef.current.position.clone().project(camera);

    groupRef.current.lookAt(camera.position);
    // Reverse direction if it hits the bounds
    if (ndcPosition.x > 1 || ndcPosition.x < -1) {
      direction.current[0] *= -1;
    }
    if (ndcPosition.y > 1 || ndcPosition.y < -1) {
      direction.current[1] *= -1;
    }
  });

  const handleKeyPress = (letter: string) => {
    const key = upperCase(letter);
    const isMatching = key === currentChar;
    callback(key, isMatching);
    // When any key is pressed, change the current character
    if (isMatching) {
      setCurrentChar("NICE!");

      setTimeout(() => {
        setCurrentChar(getRandomCharacter);
      }, 1000);
      return;
    }

    // Also, you might want to change the direction or other properties.
    direction.current = [randomDirection(), randomDirection(), 0];
    // velocity.currhent = [Math.random() * 0.3, Math.random() * 0.3];
  };

  return (
    <>
      <KeyboardInteraction onKeyDown={(e) => handleKeyPress(e.key)} />
      <group
        ref={groupRef}
        onClick={() => handleKeyPress(currentChar)}
        onPointerDown={() => handleKeyPress(currentChar)}
      >
        <Text colour={colour} text={currentChar} />
      </group>
    </>
  );
};
