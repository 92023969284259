import { useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import classes from "./WiggleModal.module.scss";

type Props = {
  title: string;
  onClose: () => void;
};
export const WiggleModal = ({ title, onClose }: Props) => {
  const [wiggle, setWiggle] = useState(false);

  const modalAnimation = useSpring({
    transform: wiggle
      ? "translate(-50%, -50%) rotate(0deg)"
      : "translate(-50%, -50%) rotate(-10deg)",
    config: { tension: 300, friction: 10 },
  });

  useEffect(() => {
    const interval = setInterval(() => setWiggle(!wiggle), 2000);
    return () => clearInterval(interval);
  }, [wiggle]);

  return (
    <animated.div style={modalAnimation} className={classes.wiggleModal}>
      <h2>Click Any Button To Start!</h2>
    </animated.div>
  );
};
