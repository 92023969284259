import { useEffect } from "react";

type Props = {
  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
};

export const KeyboardInteraction = ({
  onKeyDown = () => {},
  onKeyUp = () => {},
  onKeyPress = () => {},
}: Props) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("keypress", handleKeyPress);
    };
  });

  const handleKeyDown = (event: KeyboardEvent) => {
    onKeyDown(event);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    onKeyUp(event);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    onKeyPress(event);
  };

  return null;
};
