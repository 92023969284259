import { useState } from "react";
import { Minimiser } from "../Minimiser/Minimiser";
import { SelectionBox } from "../Selections/SelectionBox/SelectionBox";
import { Settings } from "@mui/icons-material";
import { numericData } from "../../../store/data";
import { useNumericMinimiser } from "./data";

type Props = {
  callback: (shape: string, colour: string) => void;
};

export const NumericPicker = ({ callback }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const numericMinimiser = useNumericMinimiser();
  return (
    <>
      <Minimiser
        contents={numericMinimiser}
        onClick={() => setIsOpen(!isOpen)}
      />

      {!isOpen && (
        <div>
          <Settings />
        </div>
      )}

      {isOpen &&
        numericData.map((char, i) => (
          <SelectionBox
            key={char}
            id={char}
            index={i}
            count={numericData.length}
            colour={"black"}
            background={"random"}
            callback={callback}
            brightness={200}
          >
            {char}
          </SelectionBox>
        ))}
    </>
  );
};
