import { PropsWithChildren, useEffect, useState } from "react";
import ReactDOM from "react-dom";

type Props = {
  portalId: string;
};
export const Portal = ({ portalId, children }: PropsWithChildren<Props>) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  if (!mounted) {
    return null;
  }

  const portalRoot = document.getElementById(portalId);
  if (!portalRoot) return null;
  return ReactDOM.createPortal(children, portalRoot);
};

export const PortalDiv = ({ portalId }: Props) => {
  return <div id={portalId} />;
};
