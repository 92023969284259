import { useSpring, a } from "@react-spring/three";
import { PropsWithChildren, useEffect } from "react";

type Props = {
  disableRotation?: boolean;
};

const randomValue = () => Math.random() * Math.PI * 2 - Math.PI;
const randomRotation = () => Math.random();

export const LetterAnimation = ({
  disableRotation,
  children,
}: PropsWithChildren<Props>) => {
  const [spring, api] = useSpring(
    () => ({
      from: {
        rotation: [0, 0, 0],
        scale: [1, 1, 1],
        position: [0, 0, 0],
      },
      config: { friction: 30, tension: 140, mass: 5 },
    }),
    []
  );

  const runAnimation = () => {
    const uniformScale = 1 + Math.random();
    const randomizeMesh = () => {
      api.start({
        rotation: [
          disableRotation ? randomRotation() : randomValue(),
          disableRotation ? randomRotation() : randomValue(),
          disableRotation ? randomRotation() : randomValue(),
        ],
        scale: [uniformScale, uniformScale, uniformScale],
        position: [randomValue(), randomValue(), randomValue()],
      });
    };

    // const resetRotationMesh = () => {
    //   api.start({
    //     rotation: [0, 0, 0],
    //     scale: [2, 2, 2],
    //     position: [0, 0, 0],
    //   });
    // };

    const resetMesh = (uniformScale: number) => {
      api.start({
        rotation: [0, 0, 0],
        scale: [uniformScale, uniformScale, uniformScale],
        position: [0, 0, 0],
      });
    };

    const timeout1 = setTimeout(randomizeMesh, 400);
    // const timeout2 = setTimeout(() => resetRotationMesh(), 1000); // reset after 2 seconds
    const timeout3 = setTimeout(() => resetMesh(1), 1400); // reset after 2 seconds

    return () => {
      clearTimeout(timeout1);
      // clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  };

  useEffect(runAnimation, [children, api, disableRotation]);

  return (
    <a.mesh onPointerDown={runAnimation} {...(spring as any)}>
      {children}
    </a.mesh>
  );
};
