import { KeyboardVoice, MicOff, RecordVoiceOver, VoiceOverOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import classes from "./Echo.module.scss";
import { Button } from "@mui/material";

export const EchoComponent = () => {
  const [isEchoing, setIsEchoing] = useState(false);
  const [audioContext, setAudioContext] = useState<AudioContext>();
  const [mediaStream, setMediaStream] = useState<MediaStream>();

  useEffect(() => {
    if (isEchoing) {
      // Check if the browser supports the Web Audio API and MediaDevices interface
      if (navigator.mediaDevices && window.AudioContext) {
        // Request access to the microphone
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            // Create an audio context
            const newAudioContext = new AudioContext();

            // Create a media stream source from the microphone stream
            const source = newAudioContext.createMediaStreamSource(stream);

            // Create a delay node
            const delay = newAudioContext.createDelay();
            delay.delayTime.value = 3; // Delay time in seconds

            // Connect the nodes
            source.connect(delay);
            delay.connect(newAudioContext.destination);

            // Save the audio context and media stream for cleanup later
            setAudioContext(newAudioContext);
            setMediaStream(stream);
          })
          .catch((error) => {
            console.log("Error accessing the microphone", error);
          });
      } else {
        console.log("Web Audio API or MediaDevices is not supported by your browser");
      }
    } else {
      // Cleanup when isEchoing is set to false
      if (audioContext) {
        audioContext.close();
        setAudioContext(undefined);
      }
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
        setMediaStream(undefined);
      }
    }
  }, [isEchoing]);

  return (
    <Button onClick={() => setIsEchoing(!isEchoing)}>
      {isEchoing ? <RecordVoiceOver className={classes.icon} /> : <VoiceOverOff className={classes.icon} />}
    </Button>
  );
};
