import { useEffect, useState } from "react";

export interface SpeakArgs {
  voice?: SpeechSynthesisVoice;
  text?: string;
  rate?: number;
  pitch?: number;
  volume?: number;
}

export const useSpeechSynthesis = (onEnd?: () => void) => {
  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
  const [speaking, setSpeaking] = useState<boolean>(false);

  useEffect(() => {
    // Function to fetch and update the list of voices
    const updateVoices = () => {
      setVoices(window.speechSynthesis.getVoices());
    };

    // If voices are already available, update the state
    if (window.speechSynthesis.getVoices().length !== 0) {
      updateVoices();
    }

    // Handler for when the list of available voices has changed
    const handleVoicesChanged = () => {
      updateVoices();
    };

    // Add the event listener
    window.speechSynthesis.addEventListener(
      "voiceschanged",
      handleVoicesChanged
    );

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      window.speechSynthesis.removeEventListener(
        "voiceschanged",
        handleVoicesChanged
      );
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const handleEnd = () => {
    setSpeaking(false);
    onEnd?.();
  };

  const speak = (args: SpeakArgs = {}) => {
    if (!voices.length) return;

    const { voice = null, text = "", rate = 1, pitch = 1, volume = 1 } = args;
    setSpeaking(true);
    // Firefox won't repeat an utterance that has been
    // spoken, so we need to create a new instance each time
    const utterance = new window.SpeechSynthesisUtterance();
    utterance.text = text;
    utterance.voice = voice;
    utterance.onend = handleEnd;
    utterance.rate = rate;
    utterance.pitch = pitch;
    utterance.volume = volume;
    window.speechSynthesis.speak(utterance);
  };

  const cancel = () => {
    setSpeaking(false);
    window.speechSynthesis.cancel();
  };

  return {
    supported: voices.length > 0,
    speak,
    speaking,
    cancel,
    voices,
  };
};
