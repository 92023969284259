import React from "react";
import Icon from "@mdi/react";
import * as mdi from "@mdi/js";

export const shapeMappings: { [key: string]: string } = {
  circle: mdi.mdiCircle,
  square: mdi.mdiSquare,
  triangle: mdi.mdiTriangle,
  rectangle: mdi.mdiRectangle,
  diamond: mdi.mdiCardsDiamond,
  pentagon: mdi.mdiPentagon,
  hexagon: mdi.mdiHexagon,
  octagon: mdi.mdiOctagon,
  star: mdi.mdiStar,
  heart: mdi.mdiHeart,
  moon: mdi.mdiMoonWaningCrescent
};

export const ShapeSvgs: { [id: string]: React.ReactElement } = {};
Object.entries(shapeMappings).forEach((entry) => {
  ShapeSvgs[entry[0]] = <Icon style={{ width: "inherit", height: "inherit" }} path={entry[1]} color={"inherit"} />;
});
